import type { JSX } from 'react';
import { useSelector } from 'react-redux';
import IconAppStore from '../../../../../../../assets/svg/appstore.svg';
import IconGooglePlay from '../../../../../../../assets/svg/googleplay.svg';
import IconMicrosoft from '../../../../../../../assets/svg/microsoft.svg';
import { useTranslation } from '../../../../../../../lang';
import { settingsSelector } from '../../../../../../../store/slices/application-selectors';
import styles from './D2GUnavailableModal.css';

/**
 * D2GUnavailableModal component
 */
function D2GUnavailableModal(): JSX.Element {
  const { t } = useTranslation();
  const { appleStoreURL, microsoftStoreURL, googlePlayURL } =
    useSelector(settingsSelector);

  return (
    <div className={styles.D2GUnavailableModal}>
      <p className={styles.D2GUnavailableModal__infos}>
        {t('DownloadToGoMacOsModal.appNotAvailable')}
      </p>
      <div className={styles.D2GUnavailableModal__icons}>
        <a href={appleStoreURL} target="_blank" rel="noreferrer">
          <IconAppStore
            role="img"
            aria-label={t('DownloadToGoMacOsModal.appStoreAlt')}
            title={t('DownloadToGoMacOsModal.appStoreAlt')}
          />
        </a>
        <a href={googlePlayURL} target="_blank" rel="noreferrer">
          <IconGooglePlay
            role="img"
            aria-label={t('DownloadToGoMacOsModal.playStoreAlt')}
            title={t('DownloadToGoMacOsModal.playStoreAlt')}
          />
        </a>
        <a href={microsoftStoreURL} target="_blank" rel="noreferrer">
          <IconMicrosoft
            role="img"
            aria-label={t('DownloadToGoMacOsModal.microsoftStoreAlt')}
            title={t('DownloadToGoMacOsModal.microsoftStoreAlt')}
          />
        </a>
      </div>
    </div>
  );
}

export default D2GUnavailableModal;
