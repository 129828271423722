import { Template } from '@canalplus/sdk-hodor';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import type { FetchDetails } from '../../../../../services/types';
import LoadableContentGrid from '../../../../../templates/ContentGrid';
import LoadableContentGridHighlights from '../../../../../templates/ContentGridHighlights';
import LoadableMoreInfos from '../../../../../templates/DetailV5/components/MoreInfos';
import LoadableLandingV5Container from '../../../../../templates/LandingV5';
import LoadableSportVitrine from '../../../../../templates/SportVitrine';
import LoadableSportRanking from '../../../../SportRanking';
import LoadableEpisodesListContainer from '../../EpisodesList/components/EpisodesListContainer/LoadableEpisodesListContainer';
import { TABS_PANEL_ID_PREFIX, TABS_TAB_ID_PREFIX } from '../constants';
import styles from './TabPanel.css';

const cx = classNames.bind(styles);

export type TabPanelProps = {
  URLPage: string;
  disableMetaUpdate?: boolean;
  displayTemplate?: string;
  onClickParameters?: FetchDetails['onClickParameters'];
  index: number;
};

function TabPanel({
  disableMetaUpdate = false,
  displayTemplate = '',
  onClickParameters,
  URLPage,
  index,
}: TabPanelProps): JSX.Element {
  const templateEpisodesListIsTvDevice =
    $_BUILD_RENDERMODE_CSR && Template.EpisodesList.toString();

  const getTabPanelTemplate = () => {
    const sharedProps = {
      disableMetaUpdate,
      url: URLPage,
    } as const;

    switch (displayTemplate) {
      case Template.ContentGrid:
        return (
          <LoadableContentGrid
            onClickParameters={onClickParameters}
            {...sharedProps}
          />
        );
      case Template.EpisodesList:
        return (
          <LoadableEpisodesListContainer
            onClickParameters={onClickParameters}
            {...sharedProps}
          />
        );
      case Template.Highlights:
        return (
          <LoadableContentGridHighlights
            onClickParameters={onClickParameters}
            {...sharedProps}
          />
        );
      case Template.Landing:
        return (
          <LoadableLandingV5Container
            onClickParameters={onClickParameters}
            {...sharedProps}
          />
        );
      case Template.MoreInfos:
        return <LoadableMoreInfos {...sharedProps} />;
      case Template.SportVitrine:
        return (
          <LoadableSportVitrine
            onClickParameters={onClickParameters}
            {...sharedProps}
          />
        );
      case Template.SportRanking:
        return (
          <LoadableSportRanking
            onClickParameters={onClickParameters}
            {...sharedProps}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      role="tabpanel"
      id={`${TABS_PANEL_ID_PREFIX}${index + 1}`}
      aria-labelledby={`${TABS_TAB_ID_PREFIX}${index + 1}`}
      className={cx('tabPanel', {
        // Don't add padding for HSContentRow and ContentGrid templates (inside or outside of LandingV5 template)
        'tabPanel--no-padding': [
          Template.Landing.toString(),
          Template.ContentGrid.toString(),
          Template.Highlights.toString(),
          templateEpisodesListIsTvDevice,
        ].includes(displayTemplate),
      })}
    >
      {getTabPanelTemplate()}
    </div>
  );
}

export default TabPanel;
