import classNames from 'classnames';
import type { JSX } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntersectionObserver } from '../../../../helpers/hooks/useIntersectionObserver';
import { isImmersiveSelector } from '../../../../store/slices/immersive-selectors';
import styles from './StickyTitle.css';

export type StickyTitleProps = {
  title?: string;
  mainTitleRef: React.MutableRefObject<HTMLDivElement | null>;
};

// IntersectionObserver thresholds used to create a realtime scroll-based fade-in/out effect
const THRESHOLDS = [...Array(11)].map((_, i) => +(i * 0.1).toFixed(1));
const VIEWPORT_TOP = 0;

/**
 * Fades-in/out DetailPage's alternate title when its main title is
 * scrolled-out/in the viewport. Based on IntersectionObserver, with graceful
 * degradation to CSS-based animation for legacy user agents.
 *
 * @param title Title label clamped to a single line with ellipsis
 * @param mainTitleRef `RefObject` set on DOM element containing DetailPage's main title
 */
function StickyTitle({
  title,
  mainTitleRef,
}: StickyTitleProps): JSX.Element | null {
  const isImmersive = useSelector(isImmersiveSelector);
  const showStickyTitle = !$_BUILD_RENDERMODE_CSR && isImmersive;
  const [showCssFadeinFallback, setShowCssFallback] = useState(false);

  const mainTitleTop = mainTitleRef?.current?.getBoundingClientRect()?.top;
  const isMainTitlePastViewportTop =
    mainTitleTop !== undefined ? mainTitleTop <= VIEWPORT_TOP : false;

  const { refCallback, entries } =
    useIntersectionObserver({
      threshold: THRESHOLDS,
      isEnabled: showStickyTitle,
    }) || {};

  // update element of useIntersectionObserver
  useEffect(() => {
    refCallback(mainTitleRef.current);
  }, [refCallback, mainTitleRef]);

  const titleStyles: React.CSSProperties =
    entries?.[0]?.intersectionRatio !== undefined && isMainTitlePastViewportTop
      ? { opacity: (1 - entries?.[0]?.intersectionRatio).toFixed(2) }
      : { transition: 'opacity 0.2s ease' };

  const handleCssFallback = useCallback(() => {
    const mainTitleBottom =
      mainTitleRef?.current?.getBoundingClientRect()?.bottom;
    if (mainTitleBottom === undefined) {
      return;
    }
    setShowCssFallback(mainTitleBottom <= VIEWPORT_TOP);
  }, [mainTitleRef]);

  useEffect(() => {
    // Do nothing on browsers with IntersectionObserver support...
    if (window.IntersectionObserver) {
      return;
    }

    // else, handle graceful degradation to CSS fade-in/out animation and `scroll` event listener
    window.addEventListener('scroll', handleCssFallback, true);
    return () => {
      window.removeEventListener('scroll', handleCssFallback, true);
    };
  }, [handleCssFallback]);

  return showStickyTitle ? (
    <div
      className={classNames(styles.stickyTitle, {
        [styles['stickyTitle--css-fadein-fallback']!]: showCssFadeinFallback,
      })}
      data-testid="sticky-title"
      style={titleStyles}
    >
      {title && <span>{title}</span>}
    </div>
  ) : null;
}

export default StickyTitle;
