import { ButtonIcon, MaterialShareSvg } from '@canalplus/dive';
import { ModalV2 } from '@canalplus/mycanal-sharedcomponent';
import type { JSX } from 'react';
import { Fragment, useState } from 'react';
import Badge from '../../../../../../components/Badge/Badge';
import Sharing from '../../../../../../components/Sharing/Sharing';
import { useTranslation } from '../../../../../../lang';
import styles from './SharingButton.css';

type ShareButtonProps = {
  sharingUrl: string;
  isDisabled?: boolean;
  label?: string;
};

function SharingButton({
  isDisabled,
  sharingUrl,
  label,
}: ShareButtonProps): JSX.Element {
  const [isModalOpen, setOpenModal] = useState(false);
  const { t } = useTranslation();

  const onClickHandler = () => setOpenModal(true);

  return (
    <Fragment>
      {$_BUILD_RENDERMODE_CSR ? (
        <button
          className={styles.sharingButton}
          data-testid="shared-button"
          onClick={onClickHandler}
          tabIndex={0}
          type="button"
          disabled={isDisabled}
          aria-label={label}
        >
          <Badge theme="button" disable={isDisabled}>
            <MaterialShareSvg data-testid="iconSharing" />
            {label && (
              <span className={styles.sharingButton__text}>{label}</span>
            )}
          </Badge>
        </button>
      ) : (
        <ButtonIcon
          label={label}
          aria-label={label}
          data-testid="shared-button"
          icon={<MaterialShareSvg data-testid="iconSharing" />}
          onClick={onClickHandler}
          disabled={isDisabled}
        />
      )}

      {isModalOpen && (
        <ModalV2
          size="small"
          type="sharing"
          onClose={() => setOpenModal(false)}
          title={t('Sharing.title')}
        >
          <Sharing sharingUrl={sharingUrl} />
        </ModalV2>
      )}
    </Fragment>
  );
}

export default SharingButton;
