import { getQsValueByKey } from '@canalplus/mycanal-commons';
import type { ApiV2ActionLayout } from '@dce-front/hodor-types/api/v2/action_layout/definitions';
import type { ApiV2DetailActionLayout } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import classNames from 'classnames';
import type { JSX } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { QueryKeys } from '../../../../constants/queryKeys';
import { useAppHistory } from '../../../../helpers/hooks/reactRouter';
import { useOnFocusable } from '../../../../helpers/hooks/useOnFocusable';
import { useQueryTemplate } from '../../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import { FocusManager } from '../../../../helpers/oneNavigation/FocusManager';
import { FetchRequestTypes } from '../../../../services/types';
import { profileIdSelector } from '../../../../store/slices/user-selectors';
import { QS_PROCESS_STATE } from '../../../../templates/FunnelTvod/helpers/const';
import { useDetailContext, useDetailDispatch } from '../../data/provider';
import { setIsFunnelTvodOpened } from '../../data/store/actions';
import styles from '../DetailV5.css';
import CallbackModal from './CallbackModal/CallbackModal';
import PrimaryActions from './PrimaryActions/PrimaryActions';
import SecondaryActions from './SecondaryActions/SecondaryActions';
import { useOnSuccess } from './useOnSuccess';

export type ActionLayoutProps = {
  actionLayout?: ApiV2DetailActionLayout;
  scrollToTab: (tabIndexToScroll: number | null) => void;
  toggleActionLayoutLoading: (value: boolean) => void;
  focusManager?: FocusManager;
};

function ActionLayout({
  actionLayout,
  scrollToTab,
  toggleActionLayoutLoading,
  focusManager,
}: ActionLayoutProps): JSX.Element {
  const profileId = useSelector(profileIdSelector);
  const history = useAppHistory();
  const detailDispatch = useDetailDispatch();
  const [isCallbackModalOpen, setOpenCallbackModal] = useState(false);
  const { callbackMessage } = useDetailContext();

  const isQSCallbackState =
    getQsValueByKey(history.location.search, QS_PROCESS_STATE) !== '';

  const {
    actionLayoutPerso,
    primaryActions: primaryActionsFallback,
    secondaryActions: secondaryActionsFallback,
  } = actionLayout || {};
  const [{ data, isLoading, isFetching, isError }] =
    useQueryTemplate<ApiV2ActionLayout>(
      actionLayoutPerso?.URLPage,
      {
        template: FetchRequestTypes.ActionLayout,
        onClickParameters: actionLayoutPerso?.parameters,
        options: { profileId, queryKeyPrefix: QueryKeys.DetailActionLayout },
      },
      {
        enabled: !!actionLayoutPerso?.URLPage,
      }
    );

  useOnSuccess(data);

  const handleCloseCallbackModal = useCallback(() => {
    /**
     * Remove callbackState from path
     */
    history.replace({
      ...history.location,
      search: '',
    });
    setOpenCallbackModal(false);
  }, [history]);

  useEffect(() => {
    toggleActionLayoutLoading(isLoading);
  }, [isLoading, toggleActionLayoutLoading]);

  useOnFocusable(
    focusManager,
    !!data || !actionLayoutPerso?.URLPage || isError
  );

  useEffect(() => {
    if (callbackMessage && isQSCallbackState) {
      setOpenCallbackModal(true);
    }
  }, [callbackMessage, isQSCallbackState]);

  useEffect(() => {
    // prevent the trailer preview starting when the callbackModal is opened
    detailDispatch(setIsFunnelTvodOpened(isCallbackModalOpen));
  }, [detailDispatch, isCallbackModalOpen]);

  const primaryActions =
    data?.actionLayout?.primaryActions || primaryActionsFallback;
  const secondaryActions =
    data?.actionLayout?.secondaryActions || secondaryActionsFallback;

  // Don't active binder until we've finished loading since unmount can occurs. This mess up TV focus
  // we always need to focus primary action when it's available
  return (
    <div
      className={classNames(styles.detailV5__actionLayoutInfos, {
        [styles['detailV5__actionLayoutInfos--fadeIn']!]:
          !isLoading || $_BUILD_RENDERMODE_CSR,
      })}
    >
      <PrimaryActions
        isLoading={isLoading}
        primaryActions={primaryActions}
        scrollToTab={scrollToTab}
      />
      {secondaryActions && (
        <SecondaryActions
          secondaryActions={secondaryActions}
          isFetchingPushOpinion={isFetching}
          hasPushOpinion={data?.pushOpinion}
        />
      )}
      {isCallbackModalOpen && (
        <CallbackModal
          onCloseModal={handleCloseCallbackModal}
          callbackMessage={callbackMessage}
        />
      )}
    </div>
  );
}

export default ActionLayout;
