import type { ApiV2HapiGetMediaUrl } from '@dce-front/hodor-types';
import type { ApiV2CoverTrailer } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import {
  DEFAULT_CACHE_TIME,
  DEFAULT_STALE_TIME,
} from '../../../constants/reactQuery';
import { useQueryTemplate } from '../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import { FetchRequestTypes } from '../../../services/types';
import type { VideoContent } from '../types';

type UseGetVideoContentFromUrlMediaOptions = {
  /**
   * Url to fetch the contentID for onePlayer
   */
  urlMedias: ApiV2CoverTrailer['URLMedias'];
  /**
   * Fetch request type for url medias request
   */
  template?: FetchRequestTypes;
};

export function useGetVideoContentFromUrlMedia({
  urlMedias,
  template = FetchRequestTypes.MediaUrl,
}: UseGetVideoContentFromUrlMediaOptions): VideoContent | undefined {
  // Fetch data for the video
  const [{ data: dataMedia }] = useQueryTemplate<ApiV2HapiGetMediaUrl>(
    urlMedias,
    {
      template,
    },
    {
      enabled: !!urlMedias,
      staleTime: DEFAULT_STALE_TIME,
      gcTime: DEFAULT_CACHE_TIME,
    }
  );

  if (!dataMedia) {
    return undefined;
  }

  const { contentID, playsets } = dataMedia.detail.informations || {};
  const {
    encryption,
    videoURL,
    // @ts-expect-error: videoUrl is received but videoURL is not declared in type
    videoUrl,
  } = playsets.available?.[0] || {};

  return {
    contentID,
    encryption:
      (encryption === 'encrypted' || !encryption) && contentID
        ? 'encrypted'
        : 'clear',
    URLVideo: videoUrl || videoURL,
  };
}
