import classNames from 'classnames';
import { RatingsStarSvg } from '../../../icons/icons';
import { RatingsStarFill, type RatingsStarProps } from './RatingsStar.types';

export default function RatingsStar({
  variant,
  fill,
  className,
}: RatingsStarProps) {
  return (
    <span className={classNames('flex h-full relative', className)}>
      <RatingsStarSvg
        className={classNames('absolute h-full', {
          'fill-dt-theme-icon-rating-icon-primary-selected':
            variant === 'primary',
          'fill-dt-theme-icon-rating-icon-secondary-selected':
            variant === 'secondary',
          'clip-left-1/2': fill === RatingsStarFill.Half,
          'hidden': fill === RatingsStarFill.Empty,
        })}
      />
      <RatingsStarSvg
        className={classNames('h-full', {
          'fill-dt-theme-icon-rating-icon-primary-unselected':
            variant === 'primary',
          'fill-dt-theme-icon-rating-icon-secondary-unselected':
            variant === 'secondary',
        })}
      />
    </span>
  );
}
