import { cva } from 'class-variance-authority';

export const tooltipViewCVA = cva(
  [
    'relative inline-flex items-start',
    'h-fit p-dt-spacing-100',
    'bg-dt-theme-surface-tooltip-tooltip rounded-dt-radius-small',
    'font-dt-font-family-system font-dt-font-weight-regular text-dt-font-size-14 text-dt-theme-text-tooltip-tooltip leading-dt-font-line-height-18 max-w-[21rem]',
  ],
  {
    variants: {
      width: {
        fluid: 'w-fit',
        fixed: 'w-fit min-w-[7.25rem]',
      },
      isLongContent: {
        true: 'flex-col',
      },
    },
  }
);
