import type { ButtonIconProps } from '@canalplus/dive';
import { ButtonIcon, Tooltip } from '@canalplus/dive';
import { ModalV2 } from '@canalplus/mycanal-sharedcomponent';
import {
  KEY_BACK,
  useActiveLayer,
  useKeyCatcher,
} from '@canalplus/one-navigation';
import { PlatformGroup } from '@canalplus/sdk-core';
import type { SecondaryActionState } from '@dce-front/hodor-types/modules/action_layout/definitions';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import IconMoodDislike from '../../../../../assets/svg/moodDislike.svg';
import IconMoodLike from '../../../../../assets/svg/moodLike.svg';
import IconMoodNeutral from '../../../../../assets/svg/moodNeutral.svg';
import Badge from '../../../../../components/Badge/Badge';
import { MoodConnected } from '../../../../../components/Mood/MoodConnected';
import { Moods } from '../../../../../constants/mood';
import { QueryKeys } from '../../../../../constants/queryKeys';
import { LAYER_IMMERSIVE_MOOD } from '../../../../../helpers/oneNavigation/layers';
import { useTranslation } from '../../../../../lang';
import { platformGroupSelector } from '../../../../../store/slices/application-selectors';
import {
  isPlayerOpenSelector,
  isTrailerSelector,
} from '../../../../../store/slices/player-selectors';
import stylesDetail from '../../DetailV5.css';
import styles from './MoodModalButton.css';

const cx = classNames.bind(styles);

const LABEL_MAPPING = {
  [Moods.Dislike]: 'Mood.dislike',
  [Moods.Like]: 'Mood.like',
  [Moods.Neutral]: 'Mood.neutral',
  [Moods.NoOpinion]: 'Mood.noopinion',
} as const satisfies Record<Moods, string>;

const ICON_COLOR_MAPPING: Record<Moods, ButtonIconProps['iconColor']> = {
  [Moods.Dislike]: 'negative',
  [Moods.Like]: 'positive',
  [Moods.Neutral]: 'neutral',
  [Moods.NoOpinion]: 'neutral',
};

const getIcon = (userMood: Moods, title: string) => {
  const NeutralIcon = <IconMoodNeutral title={title} />;

  return {
    [Moods.Dislike]: <IconMoodDislike title={title} />,
    [Moods.Like]: <IconMoodLike title={title} />,
    [Moods.Neutral]: NeutralIcon,
    [Moods.NoOpinion]: NeutralIcon,
  }[userMood];
};

export type MoodModalButtonProps = {
  customClassBadgeV5?: string;
  userRating: Moods;
  contentID?: string;
  isDisabled?: boolean;
  isMediaCardV5?: boolean;
  label?: string;
  customClass?: string;
  statesLabel?: SecondaryActionState[];
  hasPushOpinion?: boolean;
  isFetchingPushOpinion?: boolean;
};

/**
 * MoodButton Component
 *
 * This component is in charge of displaying MoodButton template
 *
 * @param customClassBadgeV5 Disable the onClick and the button
 * @param userRating nonopinion, like, dislike, neutral
 * @param openModal
 * @param id         Button's id
 */
function MoodModalButton({
  contentID,
  customClass = '',
  customClassBadgeV5,
  isDisabled = false,
  isMediaCardV5 = false,
  label,
  userRating,
  statesLabel,
  hasPushOpinion,
  isFetchingPushOpinion,
}: MoodModalButtonProps): JSX.Element {
  const { t } = useTranslation();
  const [isModalOpen, setOpenModal] = useState(false);
  const isPlayerOpen = useSelector(isPlayerOpenSelector);
  const isPlayingTrailer = useSelector(isTrailerSelector);
  const isLastPlayerTrailer = useRef<boolean | undefined>(undefined);
  const canDisplayMoodModal = useRef<boolean>(false);
  const queryClient = useQueryClient();
  const shouldEllipsis =
    useSelector(platformGroupSelector) === PlatformGroup.Orange;

  const icon = getIcon(userRating, t('Mood.disclaimer'));

  const onClickHandler = () => setOpenModal(true);
  const onCloseHandler = () => setOpenModal(false);

  useActiveLayer(LAYER_IMMERSIVE_MOOD, isModalOpen);
  useKeyCatcher(KEY_BACK, onCloseHandler, LAYER_IMMERSIVE_MOOD);

  const stateLabel = statesLabel?.find((elem) => {
    return elem.id === userRating;
  });

  useEffect(() => {
    // If button is disabled (no reco perso), do nothing
    if (isDisabled) {
      return;
    }

    // If player is not open, do nothing
    if (isPlayerOpen === undefined) {
      return;
    }

    // When player opens:
    // 1. memorize in ref whether played content is of type TRAILER
    // 2. exit useEffect
    if (isPlayerOpen) {
      isLastPlayerTrailer.current = !!isPlayingTrailer;
      canDisplayMoodModal.current = false;
      return;
    }

    // When player closes, set canDisplayMoodModal to true if is not trailer
    // The display of modal mood wait the refresh of actionLayout to have the updated value of hasPushOpinion property (see next effect on hasPushOpinion )
    if (isLastPlayerTrailer.current === false) {
      canDisplayMoodModal.current = true;
    }
  }, [isDisabled, isPlayerOpen, isPlayingTrailer]);

  /**
   * Effect to push modal Opinion when receive flag 'hasPushOpinion' from actionLayout and canDisplayMoodModal is true
   * Need listen isFetchingPushOpinion change too because 'hasPushOpinion' value can be already true if the user see again a film already complete
   * (Example when end season or end film)
   */
  useEffect(() => {
    if (
      !isFetchingPushOpinion &&
      canDisplayMoodModal.current &&
      hasPushOpinion
    ) {
      canDisplayMoodModal.current = false;
      setOpenModal(true);
    }
  }, [isFetchingPushOpinion, hasPushOpinion]);

  return (
    <>
      {$_BUILD_RENDERMODE_CSR ? (
        <button
          className={cx(styles.moodButton, stylesDetail['detailV5--focus'])}
          onClick={onClickHandler}
          data-testid="mood-button"
          tabIndex={0}
          type="button"
          disabled={isDisabled}
          aria-label={stateLabel?.ariaLabel}
        >
          <Badge
            theme="button"
            hasHoverStyle={false}
            customClass={cx(
              `moodButton--${userRating}`,
              {
                [customClass]: customClass,
              },
              { 'moodButton__badge--icon': shouldEllipsis }
            )}
            disable={isDisabled}
            customClassBadgeV5={customClassBadgeV5}
          >
            {icon}
            {isMediaCardV5 && (
              <span
                className={cx(styles.moodButton__label, {
                  'moodButton__label--ellipsis': shouldEllipsis,
                })}
              >
                {label || stateLabel?.label || t(LABEL_MAPPING[userRating])}
              </span>
            )}
          </Badge>
        </button>
      ) : (
        <Tooltip
          isVisible={false}
          content={t('MoodButton.notAvailableRecoOff')}
          position="top"
          showOnHover={isDisabled}
        >
          <ButtonIcon
            iconColor={ICON_COLOR_MAPPING[userRating]}
            data-testid="mood-button"
            disabled={isDisabled}
            aria-label={stateLabel?.ariaLabel}
            icon={icon}
            onClick={onClickHandler}
            label={label || stateLabel?.label || t(LABEL_MAPPING[userRating])}
          />
        </Tooltip>
      )}

      {isModalOpen && (
        <ModalV2
          size="small"
          type="mood"
          onClose={onCloseHandler}
          title={t('Mood.disclaimer')}
        >
          <MoodConnected
            closeModal={async () => {
              onCloseHandler();
              // refetch actionLayout to have the updated mood value on detail page
              await queryClient.refetchQueries({
                queryKey: [QueryKeys.DetailActionLayout],
              });
            }}
            contentId={contentID}
            userRating={userRating}
            statesLabel={statesLabel}
          />
        </ModalV2>
      )}
    </>
  );
}

export default MoodModalButton;
