import { DisplayMode } from '@canalplus/mycanal-commons';
import type { IAPIConfigRaw } from '@canalplus/oneplayer-types';
import { Template } from '@canalplus/sdk-hodor';
import type { JSX } from 'react';
import { Fragment, memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import DiveProgressBar from '../../../../../components/ProgressBar/DiveProgressBar';
import { PlayerPlatform } from '../../../../../constants/playerPlatforms';
import {
  PRIMARY_ACTION_SUBTYPE,
  PRIMARY_ACTION_TYPE,
} from '../../../../../constants/primaryAction';
import { TemplateTypes } from '../../../../../constants/templateTypes';
import { useAppDispatch } from '../../../../../helpers/hooks/useAppDispatch';
import { useInvariantSelector } from '../../../../../helpers/hooks/useInvariantSelector';
import { handleStreamingExternalService } from '../../../../../services/ExternalService';
import { getFeatureToggleTVoD } from '../../../../../store/slices/application-selectors';
import { launchPlayerFullScreen } from '../../../../../store/slices/player-thunk';
import { setDisplayMode } from '../../../../../store/slices/ui';
import { isNextEpisodeAutoplaySelector } from '../../../../../store/slices/user-selectors';
import { useDetailDispatch } from '../../../../../templates/DetailV5/data/provider';
import { setIsFunnelTvodOpened } from '../../../../../templates/DetailV5/data/store/actions';
import type {
  CustomPrimaryAction,
  CustomPrimaryActionOnClick,
} from '../../../../../templates/DetailV5/data/types';
import FunnelTvod from '../../../../../templates/FunnelTvod/components';
import DialogModal from '../DialogModal/DialogModal';
import type { PrimaryActionButtonProps } from '../PrimaryActionButton/PrimaryActionButton';
import PrimaryActionButton from '../PrimaryActionButton/PrimaryActionButton';
import {
  getFormattedRemainingTimeLabel,
  getPrimaryActionTypes,
  getSynchronizeUrl,
} from '../helpers';
import styles from './PrimaryActions.css';

export type PrimaryActionsProps = {
  scrollToTab: (tabIndex: number | null) => void;
  primaryActions?: CustomPrimaryAction[];
  isLoading: boolean;
};

const LIVE_SHOW_LABEL = 'Live';

/**
 * Return PrimaryActions as buttons with relevant show progress indicators and action handlers.
 */
function PrimaryActions({
  primaryActions,
  isLoading,
  scrollToTab,
}: PrimaryActionsProps): JSX.Element {
  const dispatch = useAppDispatch();
  const detailDispatch = useDetailDispatch();
  const isFeatureToggleTVoD = useInvariantSelector(getFeatureToggleTVoD);
  const isNextEpisodeAutoplay = useSelector(isNextEpisodeAutoplaySelector);
  const [isDialogModalOpen, setIsDialogModalOpen] = useState(false);
  const [showFunnelModal, setShowFunnelModal] = useState(false);

  const launchPlayer = useCallback(
    (onClick?: CustomPrimaryActionOnClick) => {
      if (!onClick) {
        return;
      }

      const { templateMode, playbackPosition, startTime } = onClick;

      if (!$_BUILD_RENDERMODE_CSR) {
        dispatch(setDisplayMode(DisplayMode.FULLWINDOWED));
      }

      const { contentID, epgID } = onClick;
      const content = (contentID || epgID) as IAPIConfigRaw['content'];
      dispatch(
        launchPlayerFullScreen({
          data: onClick,
          type:
            templateMode === Template.LiveTv ? PlayerPlatform.Live : undefined,
          localSettings: {
            content,
            params: {
              nextEpisodeAutoplay: isNextEpisodeAutoplay,
              startAt: playbackPosition || startTime,
              autoplay: true,
            },
            platform:
              templateMode === Template.LiveTv
                ? PlayerPlatform.Live
                : PlayerPlatform.Hapi,
          },
        })
      );
    },
    [dispatch, isNextEpisodeAutoplay]
  );

  const playBtnHandler = (onClick?: CustomPrimaryActionOnClick) => {
    if (onClick?.options) {
      return setIsDialogModalOpen(true);
    }
    if (onClick?.contentID || onClick?.epgID) {
      launchPlayer(onClick);
    }
    return;
  };

  const handleFunnelTvodOpenStatus = (isOpened = true) => {
    setShowFunnelModal(isOpened);
    detailDispatch(setIsFunnelTvodOpened(isOpened));
  };

  const button = primaryActions?.map((primaryAction, index) => {
    const {
      onClick: {
        URLPage,
        URLMedias,
        options,
        displayName,
        displayTemplate,
        channelName,
        tvPackID,
        anchorIndex: tabIndex = null,
      } = {},
      label,
      userProgress,
      startTime,
      endTime,
      remainingTimeLabel,
      subtype = '',
      type = '',
    } = primaryAction;
    const key = `${type}_${index}`;

    const isPrimaryActionPlayHighlighted =
      type === PRIMARY_ACTION_TYPE.Play &&
      subtype === PRIMARY_ACTION_SUBTYPE.highlighted;

    const sharedBtnProps: PrimaryActionButtonProps = {
      primaryAction,
      isLoading,
      isPrimary: index === 0 || isPrimaryActionPlayHighlighted,
    };

    const { isLiveShow, isLiveOnGoing, isStreamOnGoing } =
      getPrimaryActionTypes({ subtype });
    const isLive = isLiveShow || isLiveOnGoing;
    const showRemainingStreamTime = !!remainingTimeLabel && isStreamOnGoing;
    const showTvProgressBar = !!startTime && !!endTime;
    const formattedRemainingTimeLabel =
      showRemainingStreamTime && $_BUILD_RENDERMODE_CSR
        ? getFormattedRemainingTimeLabel(remainingTimeLabel)
        : remainingTimeLabel;

    switch (type) {
      case PRIMARY_ACTION_TYPE.Play:
        return URLPage ? (
          <PrimaryActionButton key={key} isButtonLinker {...sharedBtnProps} />
        ) : (
          <Fragment key={key}>
            <PrimaryActionButton
              handler={() => playBtnHandler(primaryAction.onClick)}
              {...sharedBtnProps}
            />
            {(userProgress || showTvProgressBar || showRemainingStreamTime) && (
              <div
                className={styles.primaryActions__remainingTimes}
                data-testid="PA-remainingTimesWrapper"
              >
                <DiveProgressBar
                  isLive={isLive}
                  progress={userProgress}
                  startTime={startTime}
                  endTime={endTime}
                  label={isLive ? LIVE_SHOW_LABEL : formattedRemainingTimeLabel}
                />
              </div>
            )}
            {isDialogModalOpen && options && (
              <DialogModal
                title={label}
                description={displayName}
                options={options}
                setIsOpen={setIsDialogModalOpen}
                playBtnHandler={playBtnHandler}
              />
            )}
          </Fragment>
        );

      case PRIMARY_ACTION_TYPE.Transactional: {
        if (displayTemplate === Template.Stub) {
          return (
            <PrimaryActionButton key={key} isButtonLinker {...sharedBtnProps} />
          );
        }
        if (
          displayTemplate === Template.ExternalSite ||
          displayTemplate === TemplateTypes.LAUNCH_ONE_SHOP ||
          isLive
        ) {
          return (
            <Fragment key={key}>
              <PrimaryActionButton
                isButtonLinker
                shouldHandleBigLabel
                {...sharedBtnProps}
              />
              {isLive && (
                <div className={styles.primaryActions__remainingTimes}>
                  <DiveProgressBar
                    isLive
                    startTime={startTime}
                    endTime={endTime}
                    label={LIVE_SHOW_LABEL}
                  />
                </div>
              )}
            </Fragment>
          );
        }
        return (
          <Fragment key={key}>
            <PrimaryActionButton
              handler={() => handleFunnelTvodOpenStatus()}
              {...{
                ...sharedBtnProps,
                primaryAction: {
                  ...sharedBtnProps.primaryAction,
                  disabled:
                    sharedBtnProps.primaryAction.disabled ||
                    !isFeatureToggleTVoD,
                },
              }}
            />

            {showFunnelModal && (
              <FunnelTvod
                url={URLPage || ''}
                setIsOpen={handleFunnelTvodOpenStatus}
              />
            )}
          </Fragment>
        );
      }
      case PRIMARY_ACTION_TYPE.Synchronize:
        return (
          <PrimaryActionButton
            key={key}
            handler={() => {
              window.location.href = getSynchronizeUrl(primaryAction);
            }}
            {...sharedBtnProps}
          />
        );

      case PRIMARY_ACTION_TYPE.Deeplink: {
        if (URLMedias !== undefined) {
          return (
            <PrimaryActionButton
              key={key}
              handler={async () => {
                await dispatch(
                  handleStreamingExternalService(
                    URLMedias,
                    channelName,
                    tvPackID
                  )
                );
              }}
              {...sharedBtnProps}
            />
          );
        }

        if (displayTemplate === Template.InternalAnchor) {
          return (
            <PrimaryActionButton
              key={key}
              handler={() => scrollToTab(tabIndex)}
              {...sharedBtnProps}
            />
          );
        }
      }
      // falls through, if no URLMedias, we want the unavailable button
      default:
        return <PrimaryActionButton key={key} {...sharedBtnProps} />;
    }
  });

  return (
    <div className={styles.primaryActions} data-testid="primary-actions">
      {button}
    </div>
  );
}

export default memo(PrimaryActions);
