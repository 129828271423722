import { ShareFacebookSvg, ShareTwitterSvg } from '@canalplus/dive';
import { ShareButtonWrapper } from '@canalplus/mycanal-sharedcomponent';
import classNames from 'classnames';
import type { JSX } from 'react';
import { FACEBOOK, TWITTER } from '../../constants/sharing';
import { useSendTrackingOnCTAEvent } from '../../helpers/tracking/useSendTrackingOnCTAEvent';
import { useTranslation } from '../../lang';
import styles from './Sharing.css';

type SharingProps = {
  sharingUrl: string;
};

function Sharing({ sharingUrl }: SharingProps): JSX.Element {
  const { t } = useTranslation();
  const sendTrackingOnCTAEvent = useSendTrackingOnCTAEvent();

  return (
    <div className={styles.sharing}>
      <ul className={styles.sharing__items}>
        <li className={styles.sharing__item} data-testid="facebook-item">
          <ShareButtonWrapper
            sharingURL={sharingUrl}
            shareText=""
            title={FACEBOOK}
            type={FACEBOOK}
            shareContent={(type: string) => sendTrackingOnCTAEvent(type)}
          >
            <ShareFacebookSvg
              className={classNames(
                styles.sharing__icon,
                styles.sharing__icon__facebook
              )}
              title={FACEBOOK}
            />
            <span className={styles.sharing__label}>Facebook</span>
          </ShareButtonWrapper>
        </li>
        <li className={styles.sharing__item} data-testid="twitter-item">
          <ShareButtonWrapper
            sharingURL={sharingUrl}
            title={TWITTER}
            type={TWITTER}
            shareContent={(type: string) => sendTrackingOnCTAEvent(type)}
            shareSite={t('ActionBar.twitterShareSite')}
          >
            <ShareTwitterSvg
              className={classNames(
                styles.sharing__icon,
                styles.sharing__icon__twitter
              )}
              title={TWITTER}
            />
            <span className={styles.sharing__label}>Twitter</span>
          </ShareButtonWrapper>
        </li>
      </ul>
    </div>
  );
}

export default Sharing;
